import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/QVrgrci95yo">
    <SEO title="Blessings of the Church - Greatest Hits" />
  </Layout>
)

export default SermonPost
